import React from "react";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import Blog from "../components/sections/blogs"
import LearnMoreHub from "../components/sections/hubs/learn-more-hub"

import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";

import networkMap from "../images/our-network/network-map.svg";
import networkMap2 from "../images/our-network/network-map2.svg";

import fibreNetwork from "../images/our-network/tab/fibre_network.jpg";
import ourInfrastructure from "../images/our-network/tab/ourinfrastructure.png";
import softwarePlatform from "../images/our-network/tab/softwareplatform_button.png";

import fibreConnectedIcon from "../images/rne/tabs/fibreconnected.svg";
import ipphoneIcon from "../images/hotel/tab/ipphone.svg";
import futureProofIcon from "../images/our-network/tab/futureproof.svg";

import nineNinePercent from "../images/our-network/99percent.svg";
import youtubeVerified from "../images/our-network/youtubeverified.svg";

import linkedInIcon from "../images/our-network/peeringlogo-white/linkedin.svg";
import netflixIcon from "../images/our-network/peeringlogo-white/netflix.svg";
import google from "../images/our-network/peeringlogo-white/google.svg";
import alibaba from "../images/our-network/peeringlogo-white/alibaba.svg";
import dropbox from "../images/our-network/peeringlogo-white/dropbox.svg";
import microsoft from "../images/our-network/peeringlogo-white/microsoft.svg";
import apple from "../images/our-network/peeringlogo-white/apple.svg";
import amazon from "../images/our-network/peeringlogo-white/amazon.svg";

import valve from "../images/our-network/peeringlogo-white/valve.svg";
import ebay from "../images/our-network/peeringlogo-white/ebay.svg";
import fb from "../images/our-network/peeringlogo-white/fb.svg";
import nz from "../images/our-network/peeringlogo-white/nz.svg";
import megaport from "../images/our-network/peeringlogo-white/megaport.svg";
import micron21 from "../images/our-network/peeringlogo-white/micron21.svg";
import limelight from "../images/our-network/peeringlogo-white/limelight.svg";
import riot_games from "../images/our-network/peeringlogo-white/riot.svg";

import iaa from "../images/our-network/peeringlogo-white/iaa.svg";
import he from "../images/our-network/peeringlogo-white/he.svg";
import edgecast from "../images/our-network/peeringlogo-white/edgecast.svg";
import cogent from "../images/our-network/peeringlogo-white/cogent.svg";
import cloudflare from "../images/our-network/peeringlogo-white/cloudflare.svg";
import fastly from "../images/our-network/peeringlogo-white/fastly.svg";
import blizzard from "../images/our-network/peeringlogo-white/blizzard.svg";
import mw from "../images/our-network/peeringlogo-white/mw.svg";

import aarnet from "../images/our-network/peeringlogo-white/aarnet.svg";
import twitch from "../images/our-network/peeringlogo-white/twitch.svg";
import pch from "../images/our-network/peeringlogo-white/pch.svg";
import vocus from "../images/our-network/peeringlogo-white/vocus.svg";
import packet from "../images/our-network/peeringlogo-white/packet.svg";
import akamai from "../images/our-network/peeringlogo-white/akamai.svg";
import softlayer from "../images/our-network/peeringlogo-white/softlayer.svg";
import oath from "../images/our-network/peeringlogo-white/oath.svg";

import bannerImage from "../images/box-headers/our_network.jpg";

import {getCategoryId} from "../utils/categories";

const OurNetwork = () => {
  const categoryId = getCategoryId("Student Accommodation");
  return (
    <Layout title="Our Network">
      <SEO title="Our Network - Fibre Network across Asia-Pacific - VostroNet">
        <meta name="description" content="The VostroNet software-defined network optimises performance with peering to major content providers. Our fibre is available across Asia-Pacific."/>
      </SEO>
      <Banner  className="font-white" data={{
        title: {
          name: "Our Network",
          className: "banner-title-sm",
        },
        subtitles: [
          {
            name: `Underpinned by world-class technology, our network delivers higher speeds, quality, and affordability, via our advanced software platform.`,
            className: "banner-text-small",
          },
        ],
        buttonData: {
          name: "Learn more",
        },
        img: bannerImage,
      }} />
      <Container className="py-5 tab-section">
        <Row>
          <Col>
            <Tabs defaultActiveKey="fibre" id="public-wifi-tab-section">
              <Tab eventKey="fibre" title="Fibre Network">
                <div className="tab-fibre">
                  <Container fluid className="py-4 tab-content">
                    <Row>
                      <Col xs={12} lg={4}>
                        <object data={fibreNetwork} className="tab-side-img block-center">
                          fibre network
                        </object>
                      </Col>
                      <Col xs={12} lg={8}>
                        <Row className="my-3">
                          <Col xs={3} sm={2} md={1}>
                            <object data={fibreConnectedIcon} className="tab-img">
                              fibre connected
                            </object>
                          </Col>
                          <Col>
                            <div className="tab-title align-middle">
                              {"Connections Across Asia - Pacific Region"}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="tab-desc">
                              {"As a licensed carrier, VostroNet owns and maintains dedicated fibre connections in locations across the Asia-Pacific. By managing the connection from the site through to the data centre, we provide a great user experience."}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Tab>

              <Tab eventKey="extended" title="Extended Network Options">
                <div className="tab-extended">
                  <Container fluid className="py-4 tab-content">
                    <Row>
                      <Col xs={12} md={4}>
                        <object data={ourInfrastructure} className="tab-side-img block-center">
                          our infrastructure
                        </object>
                      </Col>
                      <Col xs={12} md={8}>
                        <Row className="my-3">
                          <Col xs={3} sm={2} md={1}>
                            <object data={ipphoneIcon} className="tab-img">
                              ip phone
                            </object>
                          </Col>
                          <Col>
                            <div className="tab-title align-middle">
                              {"Offers Ethernet and IP Transit Options"}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="tab-desc">
                              {"In addition to our extensive fibre network, we also offer lit ethernet layer-2 and IP transit layer-3 options to business customers. VostroNet provides IP transit out of data centres to offices."}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Tab>

              <Tab eventKey="software" title="Software-Defined">
                <div className="tab-extended">
                  <Container fluid className="py-4 tab-content">
                    <Row>
                      <Col xs={12} md={4}>
                        <img src={softwarePlatform} alt="software platform" className="tab-side-img block-center" />
                      </Col>
                      <Col xs={12} md={8}>
                        <Row className="my-3">
                          <Col xs={3} sm={2} md={1}>
                            <object data={futureProofIcon} className="font-blue tab-img">
                              {"future proof"}
                            </object>
                          </Col>
                          <Col>
                            <div className="tab-title align-middle">
                              {"World-class Technology"}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="tab-desc">
                              {"Underpinned by world-class technology, we deliver higher speeds, quality and affordability, via our advanced software-defined network."}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <div className="au-nz-map bg-grey py-5">
        <Container className="general">
          <Row>
            <Col>
              <div className="title font-darkblue">
                {"Australia and New Zealand Map"}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <object data={networkMap2} className="network-map-mobile">
                vostronet network map
              </object>
              <div className="network-map-container">
                <object data={networkMap} className="network-map">
                  vostronet network map
                </object>
                <div className="map-element wa">
                  WA
                </div>
                <div className="map-element sa">
                  SA
                </div>
                <div className="map-element vic">
                  VIC
                </div>

                <div className="map-element nt">
                  NT
                </div>
                <div className="map-element syd-1">
                  SYD
                </div>
                <div className="map-element act">
                  ACT
                </div>
                <div className="map-element chc">
                  CHC
                </div>

                <div className="map-element bne">
                  BNE
                </div>
                <div className="map-element syd-2">
                  SYD
                </div>
                <div className="map-element wlg">
                  WLG
                </div>

                <div className="map-element usa">
                  USA
                </div>
                <div className="map-element akl">
                  AKL
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-blue general peering py-5">
        <Container>
          <Row>
            <Col>
              <div className="title">
                {"Peering"}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="desc my-3">
                {"VostroNet peers with leading networks and content providers."}
              </div>
            </Col>
          </Row>
          <Container fluid>
            <Row>
              <Col xs={6} sm={3} lg className="peers">
                <object data={linkedInIcon}>
                  linkedin
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={netflixIcon}>
                  netflix
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={google}>
                  google
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={alibaba}>
                  alibaba
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={dropbox} alt="dropbox">
                  dropbox
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={microsoft} alt="microsoft">
                  microsoft
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={apple}>
                  apple
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={amazon}>
                  amazon
              </object>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={3} lg className="peers">
                <object data={valve}>
                  valve
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={ebay}>
                  ebay
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={fb}>
                  facebook
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={nz}>
                  New Zealand Internet Exchange Inc
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={megaport}>
                  megaport
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={micron21}>
                  micron21
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={limelight}>
                  limelight networking
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={riot_games}>
                  riot games
              </object>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={3} lg className="peers">
                <object data={iaa}>
                  Internet Association of Australia
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={he}>
                  he
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={edgecast}>
                  edgecast
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={cogent}>
                  cogent
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={cloudflare}>
                  cloudflare
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={fastly}>
                  fastly
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={blizzard}>
                  blizzard
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={mw}>
                  mw
              </object>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={3} lg className="peers">
                <object data={aarnet}>
                  aarnet
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={twitch}>
                  twitch
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={pch}>
                  pch
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={vocus}>
                  vocus
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={packet}>
                  packet
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={akamai}>
                  akamai
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={softlayer}>
                  softlayer
              </object>
              </Col>
              <Col xs={6} sm={3} lg className="peers">
                <object data={oath}>
                  oath
              </object>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>

      <div className="bg-grey general py-5">
        <Container className="py-5">
          <Row>
            <Col xs={12} md={5} className="mb-3">
              <object data={nineNinePercent} className="network-99 mb-3 block-center">
                99% positive feedback
              </object>
              <div className="desc text-center">{"99% Customer Service Positive Feedback"}</div>
            </Col>
            <Col xs={12} md={7}>
              <object data={youtubeVerified} className="youtube-verified mb-3 block-center">
                youtube verified
              </object>
              <div className="desc text-center">{"Users on VostroNet experience smooth playback when watching high-definition YouTube videos"}</div>
            </Col>
          </Row>
        </Container>
      </div>
      <Blog category={categoryId} />
      <LearnMoreHub />
    </Layout>
  );
}

export default OurNetwork;
